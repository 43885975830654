import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use( BootstrapVue );
Vue.use( IconsPlugin );

// Lodash
import VueLodash from "vue-lodash";
import lodash from "lodash";

Vue.use( VueLodash, { name : "custom", lodash : lodash } );

// Configuration vue-gtm
import VueGtm from "vue-gtm";

Vue.use( VueGtm, {
    id : "GTM-N5BVFHL", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams : {},
    defer : false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled : true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug : false, // Whether or not display console logs debugs (optional)
    loadScript : true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter : router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews : [], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick : false // Whether or not call trackView in Vue.nextTick
} );

// Tema
import "../src/assets/css/custom.scss";
import "../src/assets/css/media-query.css";

// Iniciar App
new Vue( {
    router,
    store,
    render : h => h( App )
} ).$mount( "#app" );
