import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import { ProductInterface } from "@/interfaces/ProductInterface";
import { ResellerInterface } from "@/interfaces/ResellerInterface";
import { BagInterface } from "@/interfaces/BagInterface";

Vue.use( Vuex );

export default new Vuex.Store( {
    state : {
        urlPRD : "https://www.zzvendas.com.br/",
        urlHML : "http://dev.zzvendas.com.br/",
        apiKey : "ltldGp2yNR8nhqCI6Kweu95WvPsDa5ZL7QZZmtCV",
        urlEstoque : "https://9kgro4n052.execute-api.us-east-1.amazonaws.com/PRD/",
        urlVitrine : "https://q1059sx2sh.execute-api.us-east-1.amazonaws.com/PRD/",
        urlVitrineHml : "https://nxxol9ubnk.execute-api.us-east-1.amazonaws.com/PRD/",
        urlChekout : "https://k1iwfg74o9.execute-api.us-east-1.amazonaws.com/PRD/",
        urlTelemarketing: "https://0tebdwbctk.execute-api.us-east-1.amazonaws.com/PRD/",
        urlTelemarketingHml: "https://ercs1rccqc.execute-api.us-east-1.amazonaws.com/HML/",
        urlZZLink : "https://zu67cy2pr1.execute-api.us-east-1.amazonaws.com/PRD/",
        urlZZLinkHml : "https://3vxcnib5b7.execute-api.us-east-1.amazonaws.com/PRD/",
        // urlZZLinkHml : "https://nwr5thvbki.execute-api.us-east-1.amazonaws.com/PRD/",
        date : new Date().toLocaleDateString(),
        error : 0,
        storeCodeEcommerce : "300979",
        clearCache : window.location.search.includes("ClearCache=yes") ? "yes" : "no"
    },
    getters : {
        storeCodeEcommerce( state )
        {
            return state.storeCodeEcommerce;
        },
        isZZVendas()
        {
            return window.location.host === "www.zzvendas.com.br" || window.location.host === "zzvendas.com.br";
        },
        apiKey( state )
        {
            return state.apiKey;
        },
        urlEstoque( state )
        {
            return state.urlEstoque;
        },
        url( state, getters )
        {
            return getters.isZZVendas ? state.urlPRD : state.urlHML;
        },
        urlVitrine( state, getters )
        {
            return getters.isZZVendas ? state.urlVitrine : state.urlVitrineHml;
        },
        urlChekout( state )
        {
            return state.urlChekout;
        },
        urlTelemarketing(state, getters)
        {
            return getters.isZZVendas ? state.urlTelemarketing : state.urlTelemarketingHml;
        },
        urlZZLink( state, getters )
        {
            return getters.isZZVendas ? state.urlZZLink : state.urlZZLinkHml;
        },
        clearCache( state )
        {
            return state.clearCache;
        },
        httpHeaders( state )
        {
            return {
                "headers" : {
                    "x-api-key" : state.apiKey,
                    "Content-Type" : "application/json"
                }
            };
        },
        httpHeadersCheckout()
        {
            return {
                "headers" : {
                    "x-api-key" : "9HLSADs19p5j0z25qvlHW3mmdxHKKyRv9LcBy4eF",
                    "Content-Type" : "application/json"
                }
            };
        },
        httpHeadersZZLink()
        {
            return {
                "headers" : {
                    "x-api-key" : "NG79UlfmqG4Ruz7PX0AQR62lyb2gxiCS85YDlBrs",
                    "Content-Type" : "application/json"
                }
            };
        }
    },
    actions : {
        async GetInfo( { getters }, VitrineResellerId )
        {
            const url = getters.urlVitrine
                .concat( "?action=GetInfo" )
                .concat( "&VitrineResellerId=" ).concat( VitrineResellerId )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : {};
                } )
                .catch( function ( error )
                {
                    console.error( "GetInfo->Error", error );
                    return {};
                } );
        },
        async GetInfoByNomeLoja( { getters }, NomeLoja )
        {
            const url = getters.urlVitrine
                .concat( "?action=GetInfoByNomeLoja" )
                .concat( "&NomeLoja=" ).concat( NomeLoja )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : {};
                } )
                .catch( function ( error )
                {
                    console.error( "GetInfoByNomeLoja->Error", error );
                    return {};
                } );
        },
        async getpesquisarevendedora( { getters } )
        {
            const url = getters.urlVitrine
                .concat( "?action=getpesquisarevendedora" )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : {};
                } )
                .catch( function ( error )
                {
                    console.log( "error", error );
                    return {};
                } );
        },
        async getAllProductsByReseller( { getters }, payload )
        {
            const url = (getters.urlVitrine)
                .concat( "?action=GetAllProductsByReseller" )
                .concat( "&VitrineResellerId=" ).concat( payload.VitrineResellerId )
                .concat( "&TamPagina=" ).concat( payload.TamPagina ? payload.TamPagina : "" )
                .concat( "&Pagina=" ).concat( payload.Pagina ? payload.Pagina : "" )
                .concat( "&Categorias=" ).concat( payload.Categorias ? payload.Categorias : "" )
                .concat( "&Cores=" ).concat( payload.Cores ? payload.Cores : "" )
                .concat( "&Tamanhos=" ).concat( payload.Tamanhos ? payload.Tamanhos : "" )
                .concat( "&TamanhoBolsa=" ).concat( payload.TamanhosBolsas ? payload.TamanhosBolsas : "" )
                .concat( "&Saltos=" ).concat( payload.TamanhosSaltos ? payload.TamanhosSaltos : "" )
                .concat( "&Materiais=" ).concat( payload.Material ? payload.Material : "" )
                .concat( "&FaixaPreco=" ).concat( payload.Precos ? payload.Precos : "" )
                .concat( "&Pesquisa=" ).concat( payload.Pesquisa ? payload.Pesquisa : "" )
                .concat( "&OrderBy=" ).concat( payload.OrderBy ? payload.OrderBy : "" )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {

                    if ( Vue.lodash.isEmpty( response.data.Result ) )
                    {
                        response.data.Result = [];
                    }

                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "getAllProductsByReseller->Error", error );
                    return [];
                } );
        },
        async GetFiltrosByVitrine( { getters }, VitrineResellerId )
        {
            const url = getters.urlVitrine
                .concat( "?action=GetFiltrosByVitrine" )
                .concat( "&VitrineResellerId=" ).concat( VitrineResellerId )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {

                    if ( Vue.lodash.isEmpty( response.data.Result ) )
                    {
                        return [];
                    }

                    return response.data.Result;
                } )
                .catch( function ( error )
                {
                    console.error( "GetFiltrosByVitrine->Error", error );
                    return [];
                } );
        },
        async getPrecos( { getters }, payload )
        {
            const url = getters.urlVitrine
                .concat( "?action=GetPrice&CNPJ=" )
                .concat( payload.Cnpj )
                .concat( "&CPF=" ).concat( payload.Cpf )
                .concat( "&SKUs=" ).concat( payload.Lista )
                .concat( "&IdSistema=" ).concat( payload.IdSistema )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : [];
                } )
                .catch( function ( error )
                {
                    console.error( "getPrecos->Error", error );
                    return {};
                } );
        },
        async getAllVitrinesByReseller( { getters }, Reseller )
        {

            const url = getters.urlVitrine
                .concat( "?action=GetAllVitrinesByReseller" )
                .concat( "&MarcaIdo=" ).concat( Reseller.MarcaIdo )
                .concat( "&FuncIdo=" ).concat( Reseller.FuncIdo )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : {};
                } )
                .catch( function ( error )
                {
                    console.error( "getAllVitrinesByReseller->Error", error );
                    return {};
                } );
        },
        async GetProductInfo( { getters }, payload )
        {
            const url = getters.urlVitrine
                .concat( '?action=GetProductInfo' )
                .concat( '&CdItemMaterial=' ).concat( payload.CdItemMaterial )
                .concat( '&CodLoja=' ).concat( payload.CodLoja )
                .concat( '&FuncIdo=' ).concat( payload.FuncIdo )
                .concat( '&VitrineResellerId=' ).concat( payload.VitrineResellerId )
                .concat( '&ClearCache=' ).concat( getters.clearCache )

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : {};
                } )
                .catch( function ( error )
                {
                    console.error( "GetProductInfo->Error", error );
                    return {};
                } );
        },
        async GetEstoque( { getters }, payload )
        {

            const url = getters.urlEstoque
                .concat( "?storeCode=" )
                .concat( payload.storeCode )
                .concat( "&sku=" ).concat( payload.sku )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : {};
                } )
                .catch( function ( error )
                {
                    console.error( "GetEstoque->Error", error );
                    return {};
                } );
        },
        async checkout( { getters }, payload )
        {

            const url = getters.urlVitrine
                .concat( "?action=checkout" )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.post( url, payload, getters.httpHeaders )
                .then( response =>
                {
                    return response.data.Success === true ? response.data.Result : {};
                } )
                .catch( function ( error )
                {
                    console.error( "checkout->Error", error );
                    return {};
                } );
        },
        async createLink( { getters }, id )
        {
            const url = getters.urlZZLink
                .concat( "?action=createLink" )
                .concat( "&isVitrine=S" )
                .concat( "&id=" ).concat( id );

            return await axios.post( url, {}, getters.httpHeadersZZLink )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "createLink->Error", error );
                    return {};
                } );
        },
        // Bag
        async loadBag( { commit }, reseller : ResellerInterface )
        {

            const bagDB = localStorage.getItem( "bag" );
            let bag = [];

            // string -> JSON
            if ( bagDB !== null )
            {
                bag = JSON.parse( bagDB );
            }

            return Vue.lodash.filter( bag, ( product : BagInterface ) =>
            {
                return (
                    (reseller.RevId).toString() === (product.VitrineRevendedorID).toString() &&
                    (reseller.CodLoja).toString() === (product.storeCode).toString()
                );
            } );
        },
        async clearBag( { commit }, reseller : ResellerInterface )
        {

            const bagDB = localStorage.getItem( "bag" );
            let bag = [];

            // string -> JSON
            if ( bagDB !== null )
            {
                bag = JSON.parse( bagDB );
            }

            const newBag = Vue.lodash.reject( bag, ( product : BagInterface ) =>
            {
                return (
                    (reseller.RevId).toString() === (product.VitrineRevendedorID).toString() &&
                    (reseller.CodLoja).toString() === (product.storeCode).toString()
                );
            } );

            // Atualizar carrinho
            return localStorage.setItem( "bag", JSON.stringify( newBag ) );
        },
        async addToBag( { commit }, product )
        {

            // Resgatar carrinho
            const bagDB = localStorage.getItem( "bag" );
            let bag = [];

            // string -> JSON
            if ( bagDB !== null )
            {
                bag = JSON.parse( bagDB );
            }

            // Localizar produto
            const key = {
                VitrineResellerId : product.VitrineResellerId,
                storeCode : product.storeCode,
                ean : product.ean
            };
            const indexProduct = Vue.lodash.findIndex( bag, ( product : ProductInterface ) =>
            {
                return (
                    key.VitrineResellerId === product.VitrineResellerId &&
                    key.storeCode === product.storeCode &&
                    key.ean === product.ean
                );
            } );

            // Caso encontrar incrementar quantidade
            if ( indexProduct >= 0 )
            {
                // bag[ indexProduct ].quantidade++
            }
            else
            {
                // Caso nao encontrar adicionar criar registro
                bag.push( product );
            }

            // Adicionaro ao carrinho
            return localStorage.setItem( "bag", JSON.stringify( bag ) );
        },
        async deleteProductFromBag( { commit }, product )
        {

            const bagDB = localStorage.getItem( "bag" );
            let bag = [];

            // string -> JSON
            if ( bagDB !== null )
            {
                bag = JSON.parse( bagDB );
            }

            // Encontrar produto e remove-lo
            const newBag = Vue.lodash.reject( bag, ( productAdicionado : BagInterface ) =>
            {
                return (
                    productAdicionado.storeCode === product.storeCode &&
                    productAdicionado.VitrineRevendedorID === product.VitrineRevendedorID &&
                    productAdicionado.ean === product.ean
                );
            } );

            // Atualizar carrinho
            return localStorage.setItem( "bag", JSON.stringify( newBag ) );
        },
        async updateProductFromBag( { commit }, product )
        {
            // Carregar carrinho salvo
            const bagDB = localStorage.getItem( "bag" );
            let bag : any = [];

            // string -> JSON
            if ( bagDB !== null )
            {
                bag = JSON.parse( bagDB );
            }

            // Encontrar produto
            const productFake = Vue.lodash.extend( {}, product );
            delete productFake.quantidade;
            const index = Vue.lodash.findIndex( bag, productFake );

            // Atualizar produto
            bag[ index ] = product;

            // Atualizar carrinho
            return localStorage.setItem( "bag", JSON.stringify( bag ) );
        },
        // User Confirm
        async GetConfirmEmail( { getters }, payload )
        {
            const url = getters.urlVitrine
                .concat( "?action=GetConfirmEmail" )
                .concat( "&ChaveConfirmacao=" ).concat( payload.ChaveConfirmacao )
                .concat( "&Email=" ).concat( payload.Email )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "GetConfirmEmail->Error", error );
                    return {};
                } );
        },
        // Recovery
        async esquecisenha( { getters }, email )
        {
            const url = getters.urlVitrine
                .concat( "?action=esquecisenha" )
                .concat( "&Email=" ).concat( email )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "esquecisenha->Error", error );
                    return {};
                } );
        },
        async validarToken( { getters }, payload )
        {
            const url = getters.urlVitrine
                .concat( "?action=validarToken" )
                .concat( "&Token=" ).concat( payload.Token )
                .concat( "&Email=" ).concat( payload.Email )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "validarToken->Error", error );
                    return {};
                } );
        },
        async alterarsenha( { getters }, payload )
        {
            const url = getters.urlVitrine
                .concat( "?action=alterarsenha" )
                .concat( "&Token=" ).concat( payload.Token )
                .concat( "&Email=" ).concat( payload.Email )
                .concat( "&NovaSenha=" ).concat( payload.NovaSenha )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "alterarsenha->Error", error );
                    return {};
                } );
        },
        // Cadastro
        async GetMarcasParticipantes( { getters } )
        {
            const url = getters.urlVitrine
                .concat( "?action=GetMarcasParticipantes" )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "GetMarcasParticipantes->Error", error );
                    return {};
                } );
        },
        async GetLojasParticipantes( { getters }, MarcaIdo )
        {
            const url = getters.urlVitrine
                .concat( "?action=GetLojasParticipantes" )
                .concat( "&MarcaIdo=" ).concat( MarcaIdo )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "GetLojasParticipantes->Error", error );
                    return {};
                } );
        },
        async CheckCpf( { getters }, CPF )
        {
            const url = getters.urlVitrine
                .concat( "?action=CheckCpf" )
                .concat( "&CPF=" ).concat( CPF )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "CheckCpf->Error", error );
                    return {};
                } );
        },
        async CheckEmail( { getters }, Email )
        {
            const url = getters.urlVitrine
                .concat( "?action=CheckEmail" )
                .concat( "&Email=" ).concat( Email )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeaders )

                .then( response =>
                {
                    return response.data;
                } )

                .catch( function ( error )
                {
                    console.error( "CheckEmail->Error", error );
                    return {};
                } );
        },
        async PreCadastro( { getters }, formData )
        {
            const url = getters.urlVitrine
                .concat( "?action=PreCadastro" )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.post( url, formData, getters.httpHeaders )

                .then( response =>
                {
                    return response.data;
                } )

                .catch( function ( error )
                {
                    console.error( "PreCadastro->Error", error );
                    return {};
                } );
        },
        async GetDadosFormularioAvaliacao( { getters }, payload )
        {
            const url = getters.urlTelemarketing
                .concat( "?action=obter-pesquisa" )
                .concat( "&research_id=" ).concat( payload.research_id )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeadersCheckout )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "obter-pesquisa->Error", error );
                    return {};
                } );
        },
        async AvaliarAtendimento( { getters }, payload )
        {
            let queryParam = "";
            Object.keys(payload).forEach((key) => {
                queryParam += `&${key}=${payload[key]}`;
            });
            const url = getters.urlTelemarketing
                .concat( "?action=votar-pesquisa" )
                .concat( queryParam )
                .concat( "&ClearCache=" ).concat( getters.clearCache );

            return await axios.get( url, getters.httpHeadersCheckout )
                .then( response =>
                {
                    return response.data;
                } )
                .catch( function ( error )
                {
                    console.error( "votar-pesquisa->Error", error );
                    return {};
                } );
        }
    }
} );
