import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use( VueRouter );

const routes : Array<RouteConfig> = [
    // Home
    { path : "/", name : "institucional", component : () => import(/* webpackChunkName: "institucional" */ "../views/site_institucional/home.vue"), props : true },
    { path : "/duvidas-frequentes", name : "duvidas-frequentes", component : () => import(/* webpackChunkName: "duvidas-frequentes" */ "../views/site_institucional/duvidas-frequentes.vue"), props : true },
    { path : "/download", name : "download", component : () => import(/* webpackChunkName: "download" */ "../views/site_institucional/download.vue") },
    { path : "/revendedoras", name : "revendedoras", component : () => import(/* webpackChunkName: "revendedoras" */ "../views/site_institucional/revendedoras.vue") },
    // Página não encontrada
    { path : "/page-not-found", name : "page-not-found", component : () => import(/* webpackChunkName: "page-not-found" */ "../views/errors/page-not-found.vue"), meta : { visible : false } },
    // Usuário
    { path : "/avaliacao/:code", name : "avaliacao", component : () => import(/* webpackChunkName: "avaliacao" */ "../views/usuario/avaliacao.vue"), props : true },
    { path : "/cadastro", name : "cadastro", component : () => import(/* webpackChunkName: "cadastro" */ "../views/usuario/cadastro.vue"), props : true },
    { path : "/obrigado", name : "obrigado", component : () => import(/* webpackChunkName: "obrigado" */ "../views/usuario/obrigado.vue"), props : true },
    { path : "/termos-de-uso", name : "termos-de-uso", component : () => import(/* webpackChunkName: "termos-de-uso" */ "../views/usuario/termos-de-uso.vue"), props : true },
    { path : "/confirmar-conta/:ChaveConfirmacao/:Email", name : "confirmar-conta", component : () => import(/* webpackChunkName: "confirmar-conta" */ "../views/usuario/confirmar-conta.vue"), props : true },
    { path : "/recuperar-senha", name : "recovery", component : () => import(/* webpackChunkName: "recovery" */ "../views/usuario/recovery.vue"), props : true },
    { path : "/recuperar-senha/:email/:code", name : "recoveryStep2", component : () => import(/* webpackChunkName: "recoveryStep2" */ "../views/usuario/recovery.vue"), props : true },
    // Vitrine
    {
        path : "/vitrine/:VitrineResellerId",
        name : "vitrine",
        props : true,
        component : () => import(/* webpackChunkName: "vitrine" */ "../views/vitrine/vitrine.vue"),
        children : [
            {
                path : ":CdIteMaterial",
                name : "product",
                props : true,
                components : {
                    product : () => import(/* webpackChunkName: "product" */ "../views/vitrine/product/product.vue")
                },
                meta : {
                    showSidebar : true,
                    showModal : true
                }
            }
        ]
    },
    { path : "/atendimento/:idAtendimento", name : "redirect", component : () => import(/* webpackChunkName: "redirect" */ "../views/vitrine/redirect/redirect.vue"), props : true },
    // Loja Personalizada
    { path : "/:url_custom", name : "url_custom", component : () => import(/* webpackChunkName: "url_custom" */ "../views/site_institucional/url_custom.vue"), props : true }
];

const router = new VueRouter( {
    mode : "history",
    routes : routes
} );

export default router;
