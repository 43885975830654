<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

export default {
    name: 'home',
    assetsDir: 'assets/',
    created() {

        // Verificar se veio algo com hash
        const hash = window.location.hash;
        if (hash.trim()) {
            window.location.href = window.location.href.replace('#/', '')
            setTimeout(() => window.location.reload(), 500)
        }

        this.$router.beforeEach((to, from, next) => {
            next()
        });

    }
}
</script>
